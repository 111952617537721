.grid {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row {
  font-size: 15vw;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 15vw;
}

.row div {
  width: 15vw;
  float: left;
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}
